/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$red: #FF1111;
$white: #ffffff;
$dark-green: #01522a;
// GLOBALS

.error {
  padding: 16px;
  color: $white;
  background-color: $red;
}

.home-logo {
  margin: 0 0 10px 0;
  width: 100%;
}

.full-height {
  height: 100%;
}

.margin-lr-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.footer-margin {
  // padding: 0 25px 0 25px;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.show-line-break {
  white-space: pre-wrap;
}
.bold{
  font-weight: bold;
}
.loading {
  color: black;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.important {
  color: $dark-green;
  font-weight: bold;
  font-size: larger;
}

.full-center {
  width: 100%;
  text-align: center;
}

.club-text {
  padding: 15px;
}
.read-label {
  margin-bottom: 5px;
  font-size: smaller;
}
.read-text {
  padding: 5px 15px 0 15px;
}
.pre-wrap {
  white-space: pre-wrap;
}
.small {
  font-size: small;
}
.xsmall {
  font-size: xx-small;
}
ckeditor {
  color: black !important;
}
.padding-15 {
  padding: 15px;
}
pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}